import store from '@/store';
import { createRouter, createWebHistory } from 'vue-router';

const loginGuard = async (to, from, next) => {
    if (store.getters.isLoggedIn === true) {
        next();
    } else {
        const res = await store.dispatch('refresh');
        if (res) {
            if (store.getters.isGeenKlant === false) {
                await store.dispatch('logout');
                next('/login');
            }
            next();
        } else {
            window.sessionStorage.setItem('preLoginRoute', to.fullPath);
            next('/login');
        }
    }
};

const matchmakerGuard = async (to, from, next) => {
    if (store.getters.isMatchmaker) {
        next();
    } else {
        next('/intake');
    }
};

const adminGuard = async (to, from, next) => {
    if (store.getters.isAdmin) {
        next();
    } else {
        next('/');
    }
};

const routes = [
    // Bemiddelingsmodule
    {
        path: '/',
        name: 'home',
        beforeEnter: [loginGuard, matchmakerGuard],
        component: () => import(/* webpackChunkName: "bemiddeling" */ '../views/BemiddelingView.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
    },
    {
        path: '/wachtwoord-vergeten',
        name: 'reset',
        component: () => import(/* webpackChunkName: "reset" */ '../views/PasswordResetView.vue'),
    },
    {
        path: '/admin',
        name: 'admin',
        beforeEnter: [loginGuard, adminGuard],
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminView.vue'),
    },
    // Fotomodule
    {
        path: '/foto',
        name: 'foto',
        beforeEnter: [loginGuard, matchmakerGuard],
        component: () => import(/* webpackChunkName: "foto" */ '../extraModules/Fotomodule/FotoModule.vue'),
    },
    // Intake module
    {
        path: '/intake',
        name: 'intake',
        beforeEnter: loginGuard,
        component: () => import(/* webpackChunkName: "intake" */ '../extraModules/IntakeModule/IntakeModule.vue'),
    },
    {
        path: '/intake/:id',
        name: 'intakeweergave',
        beforeEnter: loginGuard,
        component: () =>
            import(/* webpackChunkName: "intakeweergave" */ '../extraModules/IntakeModule/IntakeWeergave.vue'),
    },
    {
        path: '/intake/:id/uitwerken',
        name: 'intakeuitwerken',
        beforeEnter: loginGuard,
        component: () =>
            import(/* webpackChunkName: "intakeuitwerken" */ '../extraModules/IntakeModule/IntakeUitwerken.vue'),
    },
    {
        path: '/intake/:id/pb',
        name: 'intakepbmaken',
        beforeEnter: loginGuard,
        component: () =>
            import(/* webpackChunkName: "intakepbmaken" */ '../extraModules/IntakeModule/IntakePBMaker.vue'),
    },
    {
        path: '/intake/:id/foto',
        name: 'intakefotouploaden',
        beforeEnter: loginGuard,
        component: () =>
            import(/* webpackChunkName: "intakefotoupload" */ '../extraModules/IntakeModule/IntakeFotoUpload.vue'),
    },
    // LEAVE LAST
    {
        path: '/:catchAll(.*)',
        name: '404',
        component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
